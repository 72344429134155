import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import FooterContent from './components/organisms/footerContent';
import HeaderSection from './components/molecules/headerSection'
import MainContent from './components/organisms/mainContent';
import Navigation from './components/organisms/navigation';
import DefaultTheme from './themes/defaultTheme';
import sizeRange from './layout/breakpoints';

const App = (props) => {
  const [navMenuIsExpanded, setNavMenuIsExpanded] = useState(false);

  const toggleNavMenuIsExpanded = (e) => {
    setNavMenuIsExpanded(!navMenuIsExpanded);
  }

  const collapseNavMenu = (e) => {
    setNavMenuIsExpanded(false);
  }

  return (
      <ThemeProvider theme={DefaultTheme}>
        <GridContainer className="App">      
          
          <HeaderGridChild onToggleNavMenu={toggleNavMenuIsExpanded} onSiteLogoClicked={collapseNavMenu}/>
          
          <NavigationGridChild expanded={navMenuIsExpanded} onChildLinkClicked={collapseNavMenu}/>
          <MainContentGridChild />

          <FooterGridChild />
        
        </GridContainer>  
      </ThemeProvider>   
  );
}

const GridContainer = styled.div`
  max-width: 1400px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  display: grid;  
  border: solid 1px ${props => props.theme.colors.mainDarkest};

  grid-template-columns: auto auto;
  grid-template-rows: auto auto 1fr auto auto;

  grid-template-areas:
    "header header"
    "navigation navigation"
    "main main"
    "contact contact"
    "footer footer";

    @media ${sizeRange.laptopOrLarger} {
      grid-template-columns: auto 1fr;
      grid-template-rows: auto 1fr auto auto;

      grid-template-areas:
        "header header"
        "navigation main"
        "contact contact"
        "footer footer";
    }
  
`;

const HeaderGridChild = styled(HeaderSection)` 
  grid-area: header;
`;

const NavigationGridChild = styled(Navigation)`
  grid-area: navigation;
`;

const MainContentGridChild = styled(MainContent)`
  grid-area: main;  
`;

const FooterGridChild = styled(FooterContent)`
  grid-area: footer;
  width: 100%;
`;

export default App;
