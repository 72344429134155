import styled from 'styled-components';
import sizeRange from '../../layout/breakpoints';

const Header2 = styled.h2`
    font-family: ${props => props.theme.headerFont.fontFamily};
    letter-spacing: ${props => props.theme.headerFont.letterSpacing};
    font-size: 1.5rem;
    color: ${props => props.theme.colors.mainDarker};

    @media ${sizeRange.phoneOnly} {
        font-size: 1.0rem;
    }
`;

export default Header2;