import React from 'react';
import styled from 'styled-components';
import sizeRange from '../../layout/breakpoints';

const HamburgerMenu = (props) => {
    return (
        <FlexContainer onClick={props.onToggleNavMenu}>
            <HamburgerLine />
            <HamburgerLine />
            <HamburgerLine />
        </FlexContainer>
    );
}

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
    box-sizing: border-box;
    padding: 20%;
    border: 2px solid ${props => props.theme.colors.mainLight};
    border-radius: 8px;

    @media ${sizeRange.phoneOnly} {
        width: 3.2rem;
        height: 3.2rem;
    }

    @media ${sizeRange.phoneOrLarger} {
        width: 3.2rem;
        height: 3.2rem;
    }

    @media ${sizeRange.tabletOrLarger} {
        width: 7.5rem;
        height: 7.5rem;
    }

    @media ${sizeRange.laptopOrLarger} {
        display: none;
    }
`

const HamburgerLine = styled.div`
    display: inline-block;
    height: 15%;
    width: 100%;
    border-radius: 2px;
    background-color: ${props => props.theme.colors.mainLightest}
`

export default HamburgerMenu;