import React from "react";
import styled from "styled-components";
import Page from "../organisms/page";
import PageSection from "../organisms/pageSection";
import Hyperlink from "../atoms/hyperlink";

import DisqualifyingCriminalOffensesForm from "../../forms/application/Disqualifying_Criminal_Offenses_2022.pdf";
import AppForChildPlacementForm from "../../forms/application/Application_for_Child_Placement_2022_JFS-01691.pdf";
import RequestForHomestudyForm from "../../forms/application/Request_for_Home_Study_Form_Revised_2017.pdf";
import ServiceAgreementForm from "../../forms/application/Service_Agreement_Revised_4-20-22.pdf";
import PaperworkCompletionForm from "../../forms/Paperwork_Completion_Form_2017.pdf";

import OhioChildAbuseClearingForm from "../../forms/homestudyforms/Ohio_Child_Abuse_Clearing_Request_Form_revised_3-2017.pdf";
import AgencyPolicyAcknowledgementForm from "../../forms/homestudyforms/Agency_Policy_Acknowledgement_Form_3-3-18_pdf_2019.pdf";
import MedicalStatementForm from "../../forms/homestudyforms/Medical_form_2022_with_agency_info.pdf";
import ReferenceLetterForm from "../../forms/homestudyforms/Adoption_Reference_Letter_2013_FILLABLE_Correct.pdf";
import AdultChildReferenceLetterForm from "../../forms/homestudyforms/Adult_Child_Reference_Letter_2013.pdf";

import AutobiographyIndividualForm from "../../forms/homestudyforms/Autobiography_individual_form_revised_5-2019_PDF_final.pdf";
import AutobiographyJointForm from "../../forms/homestudyforms/Autobiography_Joint_5-2019_PDF_final.pdf";

import FinancialForm from "../../forms/homestudyforms/Financial_Statement_ODJFS_Form_01681.pdf";
import ChildCharacteristicsForm from "../../forms/homestudyforms/Child_Characteristics_Checklist_ODJFS_Form_2013.pdf";
import ReleaseOfInformationForm from "../../forms/homestudyforms/Release_of_Info_2022.pdf";
import SafetyAuditSelfCheckForm from "../../forms/homestudyforms/Safety_Audit_Self-Check_2022_final.pdf";
import FireInspectionForm from "../../forms/homestudyforms/Fire_Inspection_Form_2_2015.pdf";
import DescriptionOfHomeForm from "../../forms/homestudyforms/Description_of_Home_form_2022_PDF.pdf";
import EvacuationPlanForm from "../../forms/homestudyforms/Evacuation_Plan_Instruction_Sheet_2015.pdf";
import HomeStudyChecklistForm from "../../forms/Checklist_for_HS_REVISED_8-04-22.pdf";
import InstructionsForCriminalClearingsForm from "../../forms/Instructions_for_Criminal_Clearings_corrected_2017.pdf";
import InstructionsForOutOfStateChildAbuseClearings from "../../forms/Instructions_for_Out_of_State_Child_Abuse_Clearing_2020.pdf";
import GuardianshipForm from "../../forms/Guardianship_Form.pdf";
import FinancialVerificationForm from "../../forms/Financial_Requirements_3-2017.pdf";
import LargeFamilyAssessmentForm from "../../forms/Large_Family_Assessment_Questionaire_6_2015.pdf";
import PostPlacementVerificationForm from "../../forms/Post_Placement_Verification_Form_5-01-18.pdf";
import FeeScheduleForm from "../../forms/Fee_Schedule_Form_Revised_1-28-22.pdf";
import ReleaseOfInformationPreviousHomeStudyForm from "../../forms/Release_of_Info_to_Previous_Agency_6-2015.pdf";

import TrainingPasswordProtectedZip from "../../forms/Training_password_protected_08_15_22.zip";
import TrainingVerificationForm from "../../forms/homestudyforms/Training_verification_form_Revised_4-20-2018_1_hr.pdf";

import UpdateChecklistForm from "../../forms/homeStudyUpdateForms/Checklist_for_UPDATE_revised_1-28-22.pdf";
import NoticeOfExpirationOfHomeStudy from "../../forms/homeStudyUpdateForms/Notification_Of_Expiration_of_HS_2020_1331_blue_final.pdf";
import AgencyPoliciesForm from "../../forms/AHS_POLICIES_3-03-18.pdf";
import AgencyLicenseForm from "../../forms/Agency_License_expires_2024.pdf";
import RequestForPostPlacementVisitForm from "../../forms/postplacementforms/Request_for_Post_Placement_Visit.pdf";

const StyledPageSection = styled(PageSection)`
	padding-bottom: 20px;
`;

const FormsPage = (props) => {
	return (
		<Page className={props.className} title="Forms">
			<StyledPageSection title="Application Packet Forms">
				<Hyperlink href={DisqualifyingCriminalOffensesForm} target="_blank">
					Disqualifying Criminal Offenses
				</Hyperlink>
				<Hyperlink href={AppForChildPlacementForm} target="_blank">
					Application for Child Placement
				</Hyperlink>
				<Hyperlink href={RequestForHomestudyForm} target="_blank">
					Request for Home Study
				</Hyperlink>
				<Hyperlink href={ServiceAgreementForm} target="_blank">
					Service Agreement
				</Hyperlink>
				<Hyperlink href={OhioChildAbuseClearingForm} target="_blank">
					Ohio State Child Abuse Clearing Form
				</Hyperlink>
				<Hyperlink href={AgencyPolicyAcknowledgementForm} target="_blank">
					Agency Policies Acknowledgement Form
				</Hyperlink>
				<Hyperlink href={PaperworkCompletionForm} target="_blank">
					Paperwork Completion Form
				</Hyperlink>
			</StyledPageSection>

			<StyledPageSection title="Home Study Forms">
				<Hyperlink href={HomeStudyChecklistForm} target="_blank">
					Home Study Checklist
				</Hyperlink>
				<Hyperlink href={InstructionsForCriminalClearingsForm} target="_blank">
					Instructions for Criminal Clearings
				</Hyperlink>
				<Hyperlink
					href={InstructionsForOutOfStateChildAbuseClearings}
					target="_blank"
				>
					Instructions for Out-of-State Child Abuse Clearings
				</Hyperlink>
				<Hyperlink href={MedicalStatementForm} target="_blank">
					Medical Statement ODJFS Form 01653
				</Hyperlink>
				<Hyperlink href={ReferenceLetterForm} target="_blank">
					Reference Letter
				</Hyperlink>
				<Hyperlink href={AdultChildReferenceLetterForm} target="_blank">
					Adult Child Reference Letter
				</Hyperlink>

				<Hyperlink href={AutobiographyIndividualForm} target="_blank">
					Autobiography (individual)
				</Hyperlink>
				<Hyperlink href={AutobiographyJointForm} target="_blank">
					Autobiography (joint)
				</Hyperlink>

				<Hyperlink href={GuardianshipForm} target="_blank">
					Guardianship Form
				</Hyperlink>
				<Hyperlink href={FinancialForm} target="_blank">
					Financial Form ODJFS Form 01681
				</Hyperlink>
				<Hyperlink href={FinancialVerificationForm} target="_blank">
					Financial Verification Form
				</Hyperlink>
				<Hyperlink href={ChildCharacteristicsForm} target="_blank">
					Child Characteristics Checklist ODJFS Form 01673A
				</Hyperlink>
				<Hyperlink href={ReleaseOfInformationForm} target="_blank">
					Release of Information
				</Hyperlink>
				<Hyperlink href={LargeFamilyAssessmentForm} target="_blank">
					Large Family Assessment Questionnaire
				</Hyperlink>
				<Hyperlink href={SafetyAuditSelfCheckForm} target="_blank">
					Safety Audit Self-Check
				</Hyperlink>
				<Hyperlink href={FireInspectionForm} target="_blank">
					Fire Inspection ODJFS Form 1200
				</Hyperlink>
				<Hyperlink href={DescriptionOfHomeForm} target="_blank">
					Description of the Home Form
				</Hyperlink>
				<Hyperlink href={EvacuationPlanForm} target="_blank">
					Evacuation Plan
				</Hyperlink>
				<Hyperlink href={PostPlacementVerificationForm} target="_blank">
					Post Placement Verification Form
				</Hyperlink>
				<Hyperlink href={FeeScheduleForm} target="_blank">
					Fee Schedule Form
				</Hyperlink>
				<Hyperlink
					href={ReleaseOfInformationPreviousHomeStudyForm}
					target="_blank"
				>
					Release of Information Previous Home Study
				</Hyperlink>
			</StyledPageSection>

			<StyledPageSection title="Training">
				<Hyperlink href={TrainingPasswordProtectedZip} target="_blank">
					Training Course Docs
				</Hyperlink>
				<Hyperlink href={TrainingVerificationForm} target="_blank">
					Training Verification Form
				</Hyperlink>
			</StyledPageSection>

			<StyledPageSection title="Update Forms">
				<Hyperlink href={UpdateChecklistForm} target="_blank">
					Update Checklist
				</Hyperlink>
				<Hyperlink href={NoticeOfExpirationOfHomeStudy} target="_blank">
					Notification of Expiration of Home Study
				</Hyperlink>
			</StyledPageSection>

			<StyledPageSection title="Post-Placement Request Form">
				<Hyperlink href={RequestForPostPlacementVisitForm} target="_blank">
					Request for Post Placement Visit
				</Hyperlink>
			</StyledPageSection>

			<StyledPageSection title="Agency Policies">
				<Hyperlink href={AgencyPoliciesForm} target="_blank">
					AHSS Agency Policies revised 3-03-2018
				</Hyperlink>
				<Hyperlink href={AgencyLicenseForm} target="_blank">
					Agency License
				</Hyperlink>
			</StyledPageSection>
		</Page>
	);
};

export default FormsPage;
