import styled from 'styled-components';

const Header3 = styled.h3`
    font-family: ${props => props.theme.headerFont.fontFamily};
    letter-spacing: ${props => props.theme.headerFont.letterSpacing};
    font-size: 1.17rem;
    color: ${props => props.theme.colors.mainDark};

    @media screen {
        font-size: 1.1rem;
    }
`;

export default Header3;