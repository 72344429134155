import React from 'react';
import styled from 'styled-components';
import Paragraph from '../atoms/paragraph';
import Page from '../organisms/page';
import { NavLink } from 'react-router-dom';

const NotFoundPage = (props) => {
    return (
        <Page className={props.className} title='Not Found'>
            <Paragraph>
                The page you have requested was not found.
            </Paragraph>

            <StyledNavLink to="/" title="A Unique Agency">Return to the Home Page</StyledNavLink>
        </Page>
    );
}

const StyledNavLink = styled(NavLink)`
    display: block;

    padding: 2px 0;

    font-family: ${props => props.theme.mainFont.fontFamily};
    letter-spacing: ${props => props.theme.mainFont.letterSpacing};
    font-weight: ${props => props.theme.mainFont.fontWeight}; 
    font-style: ${props => props.theme.mainFont.fontStyle}; 

    &:link {
        color:  ${props => props.unvisitedColor || props.theme.colors.defaultLinks.linkUnvisited};
    }

    &:visited {
        color: ${props => props.visitedColor || props.theme.colors.defaultLinks.linkVisited};
    }

    &:hover {
        color: ${props => props.hoverColor || props.theme.colors.defaultLinks.linkHover};
    }

    &:active {
        color: ${props => props.activeColor || props.theme.colors.defaultLinks.linkActive};
    }    
`

export default NotFoundPage;