import React from "react";
import Page from "../organisms/page";
import PageSection from "../organisms/pageSection";
import Hyperlink from "../atoms/hyperlink";
import HyperlinkInline from "../atoms/hyperlinkInline";
import Paragraph from "../atoms/paragraph";
import Header4 from "../atoms/header4";
import HighligtedContentNarrow from "../molecules/highlightedContentNarrow";
import AHSAddressData from "../../dataCalculators/ahsAddressData";

import AppForChildPlacementForm from "../../forms/application/Application_for_Child_Placement_2022_JFS-01691.pdf";
import ServiceAgreementForm from "../../forms/application/Service_Agreement_Revised_4-20-22.pdf";
import RequestForHomestudyForm from "../../forms/application/Request_for_Home_Study_Form_Revised_2017.pdf";
import OhioChildAbuseClearingForm from "../../forms/homestudyforms/Ohio_Child_Abuse_Clearing_Request_Form_revised_3-2017.pdf";
import AgencyPolicyAcknowledgementForm from "../../forms/homestudyforms/Agency_Policy_Acknowledgement_Form_3-3-18_pdf_2019.pdf";
import PaperworkCompletionForm from "../../forms/Paperwork_Completion_Form_2017.pdf";
import Address from "../molecules/address";

const addressData = AHSAddressData;

const StartHomeStudyPage = (props) => {
	return (
		<Page className={props.className} title="Start Home Study">
			<PageSection title="To start your home study now, download, print, and complete the following forms">
				<Hyperlink href={AppForChildPlacementForm} target="_blank">
					Application for Child Placement
				</Hyperlink>
				<Hyperlink href={ServiceAgreementForm} target="_blank">
					Service Agreement
				</Hyperlink>
				<Hyperlink href={RequestForHomestudyForm} target="_blank">
					Request for Home Study
				</Hyperlink>
				<Hyperlink href={OhioChildAbuseClearingForm} target="_blank">
					Ohio State Child Abuse Clearing Form
				</Hyperlink>
				<Hyperlink href={AgencyPolicyAcknowledgementForm} target="_blank">
					Agency Policies Acknowledgement Form
				</Hyperlink>
				<Hyperlink href={PaperworkCompletionForm} target="_blank">
					Paperwork Completion Form
				</Hyperlink>
			</PageSection>

			<PageSection title="Please mail these forms with your payment of $1,700 for a domestic home study, or $2,000 for an International home study, to:">
				<Address {...addressData} showAgencyName showAttentionLine />

				<Paragraph>
					Within 24 to 48 hours of receiving your signed application, you will
					receive an email from us to confirm receipt and to begin the home
					study.{" "}
				</Paragraph>

				<Paragraph>Please contact us anytime with any questions.</Paragraph>
			</PageSection>

			<HighligtedContentNarrow>
				<PageSection title="If you prefer, you may instead scan and email documets, and send payment via PayPal">
					<Header4>Email scanned documents to:</Header4>
					<Hyperlink href="mailto:homestudyohio@hotmail.com" itemProp="email">
						homestudyohio@hotmail.com
					</Hyperlink>
					<Header4>Pay through PayPal</Header4>
					<Paragraph>
						<ol>
							<li>
								Go to{" "}
								<HyperlinkInline
									href={
										"https://www.adoptioninfonow.com/services-available/adoption-home-study-ohio-only"
									}
									target="_blank"
								>
									AdoptionInfoNow.com/services-available/adoption-home-study-ohio-only
								</HyperlinkInline>
							</li>
							<li>Click on appropriate services</li>
							<li>Add to shopping cart and pay using PayPal</li>
						</ol>
					</Paragraph>
				</PageSection>
			</HighligtedContentNarrow>
		</Page>
	);
};

export default StartHomeStudyPage;
