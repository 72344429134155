import React from 'react';
import Header3 from '../atoms/header3';


const PageSection = (props) => {
    return (        
        <section className={props.className}>
            {props.title && <Header3>{props.title}</Header3>}
            {props.children}
        </section>
    );
}

export default PageSection;
