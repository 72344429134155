import React from 'react';
import Header2 from '../atoms/header2';


const PageTitleDisplay = (props) => {
    return (
        <Header2>{props.title}</Header2>
    );
}


export default PageTitleDisplay;