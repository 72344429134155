const AHSAddressData = {
    agencyName: 'Adoption Home Study Services of Ohio',
    attentionLine: 'Attention: Brenda Lare',
    streetAddress: '358 Edna Street',
    addressLocality: 'Alliance',
    addressRegion: 'OH',
    postalCode: 44601
};

export default AHSAddressData;
