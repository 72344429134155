import React from 'react';
import styled from 'styled-components';
import ContactInfo from '../molecules/contactInfo';
import CopyrightNotice from '../molecules/copyrightNotice';

const FooterContent = (props) => {
    return ( 
        <StyledFooter className={props.className}>
            <StyledContactInfo className={props.className} />
            <StyledCopyrightNotice className={props.className} />
        </StyledFooter>
    )
}

// For now, we want to specify our theme colors for links in the footer and that that apply to all
// links anywhere underneat it. Obviously we can always change this if we need finer-grained control,
// but I think this should do it.

const StyledFooter = styled.footer`
    width: 100%;
    display: flex;
    flex-direction: column;  

    background-color: ${props => props.theme.colors.footerSection.background};   
    border-top: 4px solid ${props => props.theme.colors.footerSection.borderTop};
    border-bottom: 4px solid ${props => props.theme.colors.footerSection.borderBottom};
 
    a:link {
        color: ${props => props.theme.colors.footerSection.linkUnvisited};
    }

    a:visited {
        color: ${props => props.theme.colors.footerSection.linkVisited};
    }

    a:hover {
        color: ${props => props.theme.colors.footerSection.linkHover};
    }

    a:active {
        color: ${props => props.theme.colors.footerSection.linkActive};
    } 
`

const StyledContactInfo = styled(ContactInfo)`
    padding: 12px 0 6px 0;
    align-self: center;
    justify-self: center;
    color: ${props => props.theme.colors.accentPrimaryLighter};
`
const StyledCopyrightNotice = styled(CopyrightNotice)`
    padding: 12px 0 6px 0;
    text-align: center;
    color: ${props => props.theme.colors.accentPrimaryLighter};
`

export default FooterContent;