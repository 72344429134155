import styled from "styled-components";
import sizeRange from "../../layout/breakpoints";
import HighlightedContent from "../molecules/highlightedContent";

const HighlightedContentNarrow = styled(HighlightedContent)`
	margin: 20px auto 40px auto;
	width: 70%;
	@media ${sizeRange.laptopOrLarger} {
		width: 60%;
	}

	h3 {
		margin-top: 0;
	}

	section {
		padding-top: 0;
		padding-bottom: 0;
	}

	ul {
		margin-bottom: 0;
		padding-bottom: 0;
	}
`;

export default HighlightedContentNarrow;
