import React from 'react';
import styled from 'styled-components';


const StyledDiv = styled.div`
    font-family: ${props => props.theme.mainFont.fontFamily};
    letter-spacing: ${props => props.theme.mainFont.letterSpacing};
    font-weight: ${props => props.theme.mainFont.fontWeight}; 
    font-style: ${props => props.theme.mainFont.fontStyle}; 
`;

const StyledInlineDiv = styled(StyledDiv)`
    display: inline;
`;


const Address = (props) => {
    
    let addressMarkup;
    
    if (props.renderDirection === "horizontal") {
        addressMarkup =
            <div>
                {props.showAgencyName && <StyledInlineDiv>{props.agencyName},&nbsp;</StyledInlineDiv>}
                {props.showAttentionLine && <StyledInlineDiv>{props.attentionLine},&nbsp;</StyledInlineDiv>}
                <StyledInlineDiv itemProp="streetAddress">{props.streetAddress},&nbsp;</StyledInlineDiv>
                <StyledInlineDiv itemProp="addressLocality">{props.addressLocality},&nbsp;</StyledInlineDiv>
                <StyledInlineDiv itemProp="addressRegion">{props.addressRegion}&nbsp;</StyledInlineDiv>
                <StyledInlineDiv itemProp="postalCode">{props.postalCode}</StyledInlineDiv>
            </div>;
    } else {
        addressMarkup =
            <div>
                {props.showAgencyName && <StyledDiv>{props.agencyName}</StyledDiv>}
                {props.showAttentionLine && <StyledDiv>{props.attentionLine}</StyledDiv>}
                <StyledDiv itemProp="streetAddress">{props.streetAddress}</StyledDiv>
                <StyledInlineDiv itemProp="addressLocality">{props.addressLocality},&nbsp;</StyledInlineDiv>
                <StyledInlineDiv itemProp="addressRegion">{props.addressRegion}&nbsp;</StyledInlineDiv>
                <StyledInlineDiv itemProp="postalCode">{props.postalCode}</StyledInlineDiv>
            </div>;
    }

    return (        
        <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
            {addressMarkup}                   
        </div>   
    );
}

export default Address;