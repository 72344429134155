import styled from 'styled-components';
import sizeRange from '../../layout/breakpoints';

const Header1 = styled.h1`
    font-family: 'Fjalla One', sans-serif;
    letter-spacing: 2px;
    word-spacing: 8px;
    font-size: 3.2rem;
    text-transform: uppercase;
    color: ${props => props.theme.colors.mainDarkest};

    @media ${sizeRange.phoneOnly} {
        font-size: 1.1rem;
        word-spacing: 2px;
    }

    @media ${sizeRange.phoneOrLarger} {
        font-size: 1.3rem;
        word-spacing: 2px;
    }

    @media ${sizeRange.tabletOrLarger} {
        font-size: 2.8rem;
    }
`

export default Header1;