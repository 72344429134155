import React from 'react';
import styled from 'styled-components';
import Hyperlink from '../atoms/hyperlink';
import Address from './address';
import AHSAddressData from '../../dataCalculators/ahsAddressData';
import sizeRange from '../../layout/breakpoints'

const ContactInfo = (props) => {

    const addressData = AHSAddressData;

    return (
        <ContactInfoParent className={props.className}>
            <div itemScope itemType="http://schema.org/Organization">
                                
                <PhoneNumbersContainer>
                    <PhoneNumberDiv>Office:&nbsp;<span itemProp="telephone">330-829-9400</span></PhoneNumberDiv>
                    <PhoneNumberDiv>Brenda's Cell:&nbsp;<span itemProp="telephone">330-206-2218</span></PhoneNumberDiv>
                    <PhoneNumberDiv>Fax:&nbsp;<span itemProp="faxNumber">330-823-1755</span></PhoneNumberDiv>
                </PhoneNumbersContainer>

                <EmailLink href="mailto:homestudyohio@hotmail.com" itemProp="email">homestudyohio@hotmail.com</EmailLink>

                <Address {...addressData} renderDirection="horizontal"/>
                
            </div>
        </ContactInfoParent>
    );
}

const verticalBuffer = "4px";

const ContactInfoParent = styled.div`
    display: inline-block;
    text-align: center;
`;

const PhoneNumbersContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin 0 auto ${verticalBuffer} auto;

    @media ${sizeRange.phoneOnly} {
        display: inline-block;
    }   

    @media ${sizeRange.tabletOrLarger} {
        flex-direction: row;
    }

    @media ${sizeRange.laptopOrLarger} {
        width: 80%;        
    }
`

const EmailLink = styled(Hyperlink)`
    padding-bottom: ${verticalBuffer};
`

const PhoneNumberDiv = styled.div`
    font-family: ${props => props.theme.mainFont.fontFamily};
    letter-spacing: ${props => props.theme.mainFont.letterSpacing};
    font-weight: ${props => props.theme.mainFont.fontWeight}; 
    font-style: ${props => props.theme.mainFont.fontStyle}; 
`;

export default ContactInfo;