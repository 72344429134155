import styled from 'styled-components';

const SpanWithLightFont = styled.span`
    font-family: ${props => props.theme.lightFont.fontFamily};
    letter-spacing: ${props => props.theme.lightFont.letterSpacing};
    font-weight: ${props => props.theme.lightFont.fontWeight}; 
    font-style: ${props => props.theme.lightFont.fontStyle}; 
    color: ${props => props.theme.colors.mainDark};
`;

export default SpanWithLightFont;

