import React from 'react';
import styled from 'styled-components';
import NavListParent from '../organisms/navListParent';
import sizeRange from '../../layout/breakpoints';

const Navigation = (props) => {
    return (
        <StyledNav {...props}>
            <NavListParent {...props}/>
        </StyledNav>
    );
}

const StyledNav = styled.nav`    

    position: absolute;
    order: 10;
    left: ${props => props.expanded ? "0" : "-100%"};

    @media ${sizeRange.laptopOrLarger} {
        position: static
    }

    width: 100%;
    background-color: ${props => props.theme.colors.mainDark};
`

export default Navigation;