import styled from "styled-components";

const Header4 = styled.h4`
	font-family: ${(props) => props.theme.headerFont.fontFamily};
	letter-spacing: ${(props) => props.theme.headerFont.letterSpacing};
	font-size: 1 rem;
	color: ${(props) => props.theme.colors.mainDark};

	@media screen {
		font-size: 1 rem;
	}
`;

export default Header4;
