// We will need to add/remove/change breakpoints as we go. As of 01-12-19, we're just
// setting this up as a placeholder to get the system working. I am coding it with the 
// approach that was suggested here: https://jsramblings.com/2018/02/04/styled-components-media-queries.html
//
// I have modified this approach slightly, to use em's instead of px's. See this blog
// post for a more detailed explanation: https://zellwk.com/blog/media-query-units/
// but basically it comes down to some bugs in Safari that can cause px- and rem-based
// media queries to behave incorrectly in some situations.
//
// Also, I'm naming my breakpoints something along the lines of what is suggested
// here: https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
//
// Pixel sizes in comments are what the em sizes would translate to if 1em is 16px.
// If we have a different default font size, these will scale accordingly. These
// pixel sizes were taken out of the jsramblings blog and converted to em's to use
// as a starting point. We'll tweak the numbers as needed as we go.  --CAL 01-12-19


const breakpoint = {  
    phoneJustBelowMax: '23.499em',     
    phoneMax: '23.5em',              // This is basically the same breakpoint as phoneJustBelowMax but just a fraction bigger, so we can do min/max ranges without overlapping.
    tabletMax: '48.1em',                // I did have this set at 48em, but it was matching on the iPad 4 in portrait. The phone layout really
                                        // works better as on iPad unless you put it in landscape.
    laptopMax: '52em',
}

const sizeRange = {
    phoneOnly: `(max-width: ${breakpoint.phoneJustBelowMax})`,
    phoneOrLarger: `(min-width: ${breakpoint.phoneMax})`,
    tabletOrLarger: `(min-width: ${breakpoint.tabletMax})`,
    laptopOrLarger: `(min-width: ${breakpoint.laptopMax})`,    
}

export default sizeRange;