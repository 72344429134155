import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import NavLinkList from '../molecules/navLinkList';
import NavigationLink from '../atoms/navigationLink';
import sizeRange from '../../layout/breakpoints';
import sizes from '../../layout/sizes';

const NavListParent = props => {

    // Using a zero-based index for index, 0 to 2, to indicate which menu section
    // is currently chosen to be expanded. If we set expandAll to true, then we'll
    // still keep the expandedSectionNumber value the same. We'll simply ignore
    // the value and expand them all, until expandAll changes back to false.

    const [expandedSectionNumber, setExpandedSectionNumber] = useState(0);
    const [expandAll, setExpandAll] = useState(false);

    // If expandAll is true, we'll just ignore these clicks. It really doesn't make
    // sense to let the user expand/collapse sections if they can't see the effect
    // of what they're doing.
    const handleOurJourneyClicked = (e) => {
        if (!expandAll) setExpandedSectionNumber(0);
    }

    const handleHowItWorksClicked = (e) => {
        if (!expandAll) setExpandedSectionNumber(1);
    }

    const handleGetStartedClicked = (e) => {
        if (!expandAll) setExpandedSectionNumber(2);
    }   

    useEffect(() => {
        const mediaQueryList = window.matchMedia(sizeRange.laptopOrLarger); 

        const handleMqlMatchChange = (evt) => {
            setExpandAll(evt.matches);        
        }

        mediaQueryList.addListener(handleMqlMatchChange);

        handleMqlMatchChange(mediaQueryList);

        return () => {
            mediaQueryList.removeListener(handleMqlMatchChange);
        }
    }, [setExpandAll]);    

    return (
        <FlexboxList {...props}>                
            <li>
                <NavLinkList 
                    title="Our Story" 
                    expanded={expandAll || expandedSectionNumber === 0} 
                    parentLinkClicked={handleOurJourneyClicked}
                    hideExpandableIcon={expandAll}>                
                    
                    <NavigationLink to="/unique" title="A Unique Agency" onClick={props.onChildLinkClicked}>A Unique Agency</NavigationLink>
                    <NavigationLink to="/profile" title="Agency Profile" onClick={props.onChildLinkClicked}>Agency Profile</NavigationLink>
                    <NavigationLink to="/testimonials" title="Testimonials" onClick={props.onChildLinkClicked}>Testimonials</NavigationLink>
                </NavLinkList>
            </li>                
            
            <li>
                <NavLinkList 
                    title="How It Works" 
                    expanded={expandAll || expandedSectionNumber === 1} 
                    parentLinkClicked={handleHowItWorksClicked}
                    hideExpandableIcon={expandAll}>
                    
                    <NavigationLink to="/services" title="Services" onClick={props.onChildLinkClicked}>Services</NavigationLink>
                    <NavigationLink to="/fees" title="Fees" onClick={props.onChildLinkClicked}>Fees</NavigationLink>
                </NavLinkList>
            </li>  

            <li>
                <NavLinkList 
                    title="Get Started" 
                    expanded={expandAll || expandedSectionNumber === 2} 
                    parentLinkClicked={handleGetStartedClicked}
                    hideExpandableIcon={expandAll}>
                    
                    <NavigationLink to="/startHomeStudy" title="Start Home Study" onClick={props.onChildLinkClicked}>Start Home Study</NavigationLink>
                    <NavigationLink to="/forms" title="Download Forms" onClick={props.onChildLinkClicked}>Download Forms</NavigationLink>
                </NavLinkList>
            </li>          
        </FlexboxList>
    )
}

const FlexboxList = styled.ul`
    padding: ${sizes.sidebar.padding};
    margin: ${sizes.sidebar.margin};
    list-style-type: none;

    flex-wrap: wrap;
    justify-content: space-around;

    flex-direction: column;

    @media ${sizeRange.tabletOrLarger} {        
        flex-direction: row;                
    }   
    
    @media ${sizeRange.laptopOrLarger} {
        flex-direction: column;
        width: ${sizes.sidebar.width};
    }    
`

export default NavListParent;

