import React from 'react';
import Page from '../organisms/page';
import Paragraph from '../atoms/paragraph';

const HomePage = (props) => {  
    return (
        <Page className={props.className} title='Home'>

            <Paragraph>Adoption Home Study Services of Ohio is an Ohio state-licensed adoption agency, designed to provide 
                    you with the information that you need to understand&mdash;and to start&mdash;the adoption process.</Paragraph>

            <Paragraph>If you are an Ohio resident, Adoption Home Study Services is here to assist you to understand your 
                complete range of adoption opportunities.</Paragraph>

            <Paragraph>As we complete your home study, we will explain your options to adopt, including private, public,
                    domestic, international, and embryo adoption.</Paragraph>

            <Paragraph>Our professional staff will provide quality, timely, confidential services and will work with you 
                to assist you to make the best choices to meet your family's needs.</Paragraph>

            <Paragraph>We will assist you and advocate for you until you reach your adoption goal.</Paragraph>

        </Page>
    );
}

export default HomePage;