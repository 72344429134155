import React from 'react';
import Page from '../organisms/page';
import Testimonial from '../molecules/testimonial'
import TestimonialsData from '../../dataCalculators/testimonialsData';

const TestimonialsPage = (props) => {    
    return (
        <Page className={props.className} title='Testimonials' >            
            {TestimonialsData.map(
                (testimonial, index) => 
                    <Testimonial key={index} {...testimonial}/> )}
        </Page>   
    );
}

export default TestimonialsPage;