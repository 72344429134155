import { lighten, darken } from 'polished';


// I'm going to keep my actual hex values here because some of them may be shared below. The consumers of
// the theme shouldn't have to care if we use the same color for multiple things. They should just be
// thinking about the semantic meaning. That way we can easily remap the actual colors here without changing
// any of the client code.
// 
// Sometimes clients may want to use the colors directly (main, mainDarker, etc.), so we'll expose these
// directly in DefaultTheme also.


// NOTE: I like this red color, possibly for the background of the footer. #804040 
// Need to load this into the theme and see if we want to replace our existing red
// with variants of this.

const mainColor = "#004995";
const accentPrimaryColor = "#37E500";
const accentSecondary = "#43009B";

const ThemeColors = {
    main: mainColor,   
    mainDarkest: darken(.75, mainColor),
    mainDarker: darken(.5, mainColor),
    mainDark: darken(.15, mainColor),
    mainLight: lighten(.25, mainColor),
    mainLighter: lighten(.67, mainColor),
    mainLightest: lighten(.75, mainColor),

    accentPrimary: accentPrimaryColor,
    accentPrimaryDarkest: darken(.42, accentPrimaryColor),
    accentPrimaryDarker: darken(.3, accentPrimaryColor),
    accentPrimaryDark: darken(.15, accentPrimaryColor),
    accentPrimaryLight: lighten(.25, accentPrimaryColor),
    accentPrimaryLighter: lighten(.55, accentPrimaryColor),
    accentPrimaryLightest: lighten(.75, accentPrimaryColor),

    accentSecondary: accentSecondary,
    accentSecondaryDarkest: darken(.75, accentSecondary),
    accentSecondaryDarker: darken(.35, accentSecondary),
    accentSecondaryDark: darken(.15, accentSecondary),
    accentSecondaryLight: lighten(.25, accentSecondary),
    accentSecondaryLighter: lighten(.55, accentSecondary),
    accentSecondaryLightest: lighten(.75, accentSecondary),
}

const DefaultTheme = {
    colors: {
        main: ThemeColors.main,   
        mainDarkest: ThemeColors.mainDarkest,
        mainDarker: ThemeColors.mainDarker,
        mainDark: ThemeColors.mainDark,
        mainLight: ThemeColors.mainLight,
        mainLighter: ThemeColors.mainLighter,
        mainLightest: ThemeColors.mainLightest,

        accentPrimary: ThemeColors.accentPrimary,
        accentPrimaryDarkest: ThemeColors.accentPrimaryDarkest,
        accentPrimaryDarker: ThemeColors.accentPrimaryDarker,
        accentPrimaryDark: ThemeColors.accentPrimaryDark,
        accentPrimaryLight: ThemeColors.accentPrimaryLight,
        accentPrimaryLighter: ThemeColors.accentPrimaryLighter,
        accentPrimaryLightest: ThemeColors.accentPrimaryLightest,

        accentSecondary: ThemeColors.accentSecondary,
        accentSecondaryDarkest: ThemeColors.accentSecondaryDarkest,
        accentSecondaryDarker: ThemeColors.accentSecondaryDarker,
        accentSecondaryDark: ThemeColors.accentSecondaryDark,
        accentSecondaryLight: ThemeColors.accentSecondaryLight,
        accentSecondaryLighter: ThemeColors.accentSecondaryLighter,
        accentSecondaryLightest: ThemeColors.accentSecondaryLightest,

        // NOTE: I'm starting to not like this approach, with having everything in the theme telling the components which
        // colors to use. This is basically turning into a global stylesheet. For now, I'm going to leave it the way it
        // is for components I've already done this for, but I'm taking a different approach with the header section,
        // using the theme colors directly but letting the components decide which colors to pull out of the theme. When
        // this is done, I should refactor all components to use the same approach (whichever one I end up liking better).

        navSection: {
            parentLink: ThemeColors.mainLightest,
            linkUnvisited: ThemeColors.mainLighter,
            linkVisited: ThemeColors.mainLighter,
            linkHover: ThemeColors.accentPrimary,
            linkActive: ThemeColors.accentPrimaryLight
        },

        footerSection: {
            background: ThemeColors.accentPrimaryDark,
            borderTop: ThemeColors.accentPrimary,
            borderBottom: ThemeColors.accentPrimary,
            linkUnvisited: ThemeColors.accentPrimaryLighter,
            linkVisited: ThemeColors.accentPrimaryLighter,
            linkHover: ThemeColors.accentPrimaryLightest,
            linkActive: ThemeColors.accentPrimaryLightest
        },

        // These are for links that are not part of the nav bar but appear elsewhere on the site.
        defaultLinks: {
            linkUnvisited: ThemeColors.mainLight,
            linkVisited: ThemeColors.mainDarkest,
            linkHover: ThemeColors.accentPrimaryDark,
            linkActive: ThemeColors.mainDarker
        }
    },    

    // TODO: Do we want to do something similar for fonts to what we did for colors? We could have a fonts parent object and
    // then also map fonts using names that have more semantic meaning.

    headerFont: {
        fontFamily: "'Fjalla One', sans-serif",
        letterSpacing: "normal",
        wordSpacing: "8px",
        fontWeight: 400
    },
    mainFont: {
        fontFamily: "'Noto Sans', sans-serif",
        letterSpacing: "normal",
        fontWeight: 400,
        fontStyle: "normal"
    },
    navHeaderFont: {
        fontFamily: "'Fjalla One', sans-serif",
        letterSpacing: "normal",
        fontWeight: 400,
        fontSize: "1.2rem",
        fontStyle: "normal"
    },
    lightFont: {
        fontFamily: "'Noto Sans', sans-serif",
        letterSpacing: "normal",
        fontWeight: 400,
        fontStyle: "italic"
    }
};

export default DefaultTheme;