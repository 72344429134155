import React from "react";
import styled from "styled-components";
import Page from "../organisms/page";
import PageSection from "../organisms/pageSection";
import Paragraph from "../atoms/paragraph";
import SpanWithLightFont from "../atoms/spanWithLightFont";
import sizeRange from "../../layout/breakpoints";
import HighlightedContentNarrow from "../molecules/highlightedContentNarrow";

const UniqueAgencyPage = (props) => {
	return (
		<Page className={props.className} title="A Unique Agency">
			<PageSection title="What Makes Us Different">
				<Paragraph>
					Adoption Home Study Services of Ohio is the only adoption agency in
					Ohio who's primary goal is to provide you with objective, detailed
					adoption information, to help you understand how the process works,
					and to provide you with all of your adoption options.
				</Paragraph>

				<Paragraph>
					Our services are designed to inform you of all of your adoption
					opportunities and to identify the advantages and the risks of each
					option. We help you to know what to ask an adoption attorney or a
					child&#8209;placing agency so that you will be given the services that
					you need. This approach helps to ensure that the time, effort, and
					money that you invest in the adoption process will be well invested.
				</Paragraph>

				<Paragraph>
					We are dedicated to working on your behalf, and we will complete your
					adoption home study in compliance with Ohio State Law.
				</Paragraph>
			</PageSection>

			<HighlightedContentNarrow>
				<PageSection title="We provide you with the following benefits">
					<StyledUL>
						<StyledLI>
							<SpanWithLightFont>
								Free phone and email consultation
							</SpanWithLightFont>
						</StyledLI>
						<StyledLI>
							<SpanWithLightFont>
								Objective, comprehensive information regarding all of Your
								options
							</SpanWithLightFont>
						</StyledLI>
						<StyledLI>
							<SpanWithLightFont>
								Timely home studies with no waiting lists
							</SpanWithLightFont>
						</StyledLI>
						<StyledLI>
							<SpanWithLightFont>
								Services provided throughout the entire state of Ohio
							</SpanWithLightFont>
						</StyledLI>
						<StyledLI>
							<SpanWithLightFont>
								Option to choose a child&#8209;placing agency inside or outside
								of Ohio
							</SpanWithLightFont>
						</StyledLI>
						<StyledLI>
							<SpanWithLightFont>
								Information that will help you know what you need to ask the
								child&#8209;placing agencies so that you choose an agency that
								will best meet your short&#8209; and long&#8209;term needs
							</SpanWithLightFont>
						</StyledLI>
						<StyledLI>
							<SpanWithLightFont>
								Professional, trained staff to assist you
							</SpanWithLightFont>
						</StyledLI>
						<StyledLI>
							<SpanWithLightFont>
								Client&#8209;focused services designed to advocate for you
								throughout the adoption process
							</SpanWithLightFont>
						</StyledLI>
						<StyledLI>
							<SpanWithLightFont>
								Support and assistance before, during, and after your adoption
								is finalized
							</SpanWithLightFont>
						</StyledLI>
					</StyledUL>
				</PageSection>
			</HighlightedContentNarrow>
		</Page>
	);
};

const StyledUL = styled.ul`
	padding-bottom: 20px;
`;

const StyledLI = styled.li`
	padding: 4px 0;
`;

export default UniqueAgencyPage;
