import React from 'react';
import Page from '../organisms/page';
import PageSection from '../organisms/pageSection';
import Paragraph from '../atoms/paragraph'

const AgencyProfilePage = (props) => {
    return (
        <Page className={props.className} title='Agency Profile'>
     
            <PageSection title='Certification'>
                <Paragraph>
                    Adoption Home Study Services of Ohio is Licensed by the Ohio Department of Job and Family Services 
                    to provide adoption services throughout the State of Ohio. All services are provided in compliance 
                    with Ohio State Law and the Ohio Department of Job and Family Services Adoption Regulations. Our 
                    international adoption homestudies meet Hague and USCIS Requirements. Domestic adoption home studies 
                    are written in compliance with all State and Federal ICPC requirements and can be used for the 
                    adoption of a child in any US state. 
                </Paragraph>
            </PageSection>


            <PageSection title='Non-Profit'>
                <Paragraph>
                    Adoption Home Study Services of Ohio is a 501 (c) (3) Non-Profit Licensed Adoption Agency. Every 
                    effort is made to keep costs and fees as low as possible.
                </Paragraph>
            </PageSection>


            <PageSection title='Credentials'>
                <Paragraph>
                    Adoption Home Study Services of Ohio maintains a network of adoption assessors who are located 
                    throughout the state of Ohio. All of the adoption assessors are Ohio State Licensed Social Workers 
                    and Ohio State Certified Adoption Assessors. All assessors have extensive adoption training. They 
                    are experienced adoption social workers who have current or previous work experience in either public 
                    child welfare or private adoption agencies.
                </Paragraph>
            </PageSection>


            <PageSection title='Qualifications'>
                <Paragraph>
                    Brenda S. Lare, MSSA, LSW is the director of Adoption Home Study Services of Ohio. Brenda is an Ohio 
                    State Licensed Social Worker and an Ohio Certified Adoption Assessor. She earned a Bachelor of Social 
                    Work Degree from the University of Akron and a Master of Social Science Administration Degree with a 
                    specialization in Child Welfare from Case Western Reserve University. Brenda worked for eight years 
                    in the Ohio Public Child Protective Service System. She is knowledgeable regarding Ohio State Adoption 
                    Law and the Ohio Department of Job and Family Services Rules and Regulations which govern the Ohio 
                    Adoption Process. She has extensive training and experience related to child development, family 
                    dynamics and adoption related issues.
                </Paragraph>
            </PageSection>

        </Page>
    );
}

export default AgencyProfilePage;