const TestimonialsData = [
	{
		quote:
			"Brenda was so supportive during our adoption journey, taking time to answer all the many questions we had about the home study process. She has a calming presence, is professional and direct, and helped to make a stressful time more pleasant. Most importantly, when our first placement fell through, she encouraged us to follow our hearts and keep trying, which led us to taking a chance on our next placement and bringing our precious daughter home. We will forever be grateful to Brenda and her staff for helping us to become parents!",
		name: "Scott and Quinn",
	},
	{
		quote:
			"It can seem like an overwhelming task. Feeling scared, excited, eager, lost, determined, impatient and every other emotion that goes along with navigating the whole adoption process. Not knowing where to begin or who to trust we took a chance with Brenda  and are so lucky we did. She guided us and supported us through it all and had in-depth knowledge needed to understand all the policies and procedures and laws of adoption.   She played an integral role in helping our family grow  – by two! We have had no regrets and highly recommend her.",
		name: "Tim and Deb",
	},
	{
		quote:
			"We have completely enjoyed working with Brenda on our family’s adoption journey. We appreciate how responsive she is to our emails and our text messages, whether to answer a question or ease a worry. We are glad Brenda is part of our adoption team!",
		name: "Melissa and Raymond",
	},
	{
		quote:
			"Brenda and her staff are very knowledgeable, friendly and take the time to answer questions and explain the process.  They handled our adoption in a matter which felt personal and were quick to respond to questions and concerns if they arose.",
		name: "Gina",
	},
	{
		quote:
			"I am incredibly thankful that I contacted Brenda when I did. Having no knowledge of the grueling process I was about to embark on she helped guide me. She was a key part of our adoption. We would not have been given our little gift two years ago had it not been for her dedication and commitment.",
		name: "Tiffany",
	},
	{
		quote:
			"I contacted Brenda in 2010 when I started my adoption journey.  At the time, I had no idea the journey would take me through three different country programs.  Brenda was a wealth of information to help me be the most prepared that I could possibly be.  She supplied me with a plethora of resources.  In addition, she helped me tremendously navigate through all of the required updates while in process and all of the post placement reports. I have three daughters home thanks to Brenda’s knowledge and expertise in the adoption community!",
		name: "Kristen",
	},
	{
		quote:
			"Brenda’s knowledge of the adoption process was a blessing for us.  She was always able to answer every question we had and was extremely pleasant in every interaction.  We have and will continue to refer other families to Brenda as they begin their adoption journey.",
		name: "Elliott and Nakita",
	},
	{
		quote:
			"We can never be grateful enough for the expedited help we received. We found that we needed a new home study at the last minute to complete our adoption on time.  They met our deadline with professionalism.  Brenda and her team really care about their clients.",
		name: "Mike and Peggy",
	},
	{
		quote:
			"Brenda has been a great help to us in our adoption journey. He work and experience in helping families come together has certainly been a blessing to us.  I recommend her highly.",
		name: "Fern",
	},
	{
		quote:
			"Brenda Lare is a consummate professional whose expertise was greatly appreciated as we went through the home study process in preparation for the adoption of our infant son in early 2016. As extremely detail-oriented professionals, who expect excellence and timeliness not only from ourselves but also from others in their particular work field, we have and will continue to highly recommend her services to other prospective foster and/or adoptive parents. We thank Brenda for playing a crucial-yet-pleasant role in our adoption journey!",
		name: "John and Amber",
	},
	{
		quote:
			"Miss Brenda is not just our assessor but our friend and encourager.  She has vast knowledge in the field of adoption and shares it will her whole heart.  She is an amazing lady who is dedicated to helping those choosing adoption.",
		name: "Diane and Steve",
	},
	{
		quote:
			"Adoption Home Study Services of Ohio was amazing in facilitating a successful adoption of our son.  Their team worked with us in a caring and efficient way to ensure we kept the process proceeding in a streamlined way.  Our family highly recommends this group of professionals.",
		name: "Jay",
	},
];

export default TestimonialsData;
