import React from "react";
import styled from "styled-components";
import Paragraph from "../atoms/paragraph";
import HighlightedContent from "../molecules/highlightedContent";
import sizes from "../../layout/breakpoints";

const Testimonial = (props) => {
	return (
		<blockquote>
			<StyledHighlightedContent>
				<StyledParagraph className={props.className}>
					&#8220;{props.quote}&#8221;
				</StyledParagraph>
				<footer>&mdash;{props.name}</footer>
			</StyledHighlightedContent>
		</blockquote>
	);
};

const StyledHighlightedContent = styled(HighlightedContent)`
	@media ${sizes.phoneOnly} {
		margin: auto 0px;
	}
`;

const StyledParagraph = styled(Paragraph)`
	margin-top: 0;
	color: ${(props) => props.theme.colors.accentPrimaryDarker};
`;

export default Testimonial;
