import React from "react";
import Page from "../organisms/page";
import PageSection from "../organisms/pageSection";
import Paragraph from "../atoms/paragraph";

const ServicesPage = (props) => {
	return (
		<Page className={props.className} title="Services">
			<PageSection title="Home Study Services">
				<Paragraph>
					Adoption Home Study Services of Ohio provides comprehensive, quality
					home study services in compliance with Ohio state law and the Ohio
					Department of Job and Family Services Regulations. We provide home
					study services for both domestic and international adoptions.
				</Paragraph>
			</PageSection>

			<PageSection title="Training Services">
				<Paragraph>
					Ohio adoption agencies are required to provide training to applicants
					regarding the adoption process and adoption issues. Adoption Home
					Study Services of Ohio incorporates the required training into the
					home study process through the use of an online training course. This
					provides you with detailed individualized training. It allows you the
					opportunity to have all of your adoption questions answered in a
					comprehensive, private and confidential manner.
				</Paragraph>
			</PageSection>

			<PageSection title="Linking and Advocacy Services">
				<Paragraph>
					Adoption Home Study Services of Ohio is not a child-placing agency.
					Because of this we provide objective, comprehensive information
					regarding all of your adoption opportunities. We assist you to
					identify your needs and we work with you to assist you to identify and
					link with the child-placing agency (domestic or international) which
					will best meet your needs. We help you to understand how to best
					choose a child-placing agency to protect your interest. We also
					provide advocacy services for you if any problems occur. We advocate
					on your behalf and assist you before, during, and after the adoption.
				</Paragraph>
			</PageSection>

			<PageSection title="Post-Placement Services">
				<Paragraph>
					Adoption Home Study Services of Ohio is certified to provide post
					placement services and will do so at your request.
				</Paragraph>
			</PageSection>
		</Page>
	);
};

export default ServicesPage;
