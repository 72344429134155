import styled from 'styled-components';
import sizeRange from '../../layout/breakpoints';

const HighlightedContent = styled.div`
    font-family: ${props => props.theme.mainFont.fontFamily};
    letter-spacing: ${props => props.theme.mainFont.letterSpacing};
    font-weight: ${props => props.theme.mainFont.fontWeight}; 
    font-style: ${props => props.theme.mainFont.fontStyle}; 

    background-color: ${props => props.theme.colors.accentPrimaryLighter};
    color: ${props => props.theme.colors.accentPrimaryDarker};
    
    border-radius: 20px;
    border: 5px solid ${props => props.theme.colors.accentPrimaryDark};

    padding: 40px;
    margin-bottom: 40px;

    @media ${sizeRange.phoneOnly} {
        padding: 30px;
        margin-bottom: 40px;
        margin-left: 16px;
        margin-right: 16px;
    }
`

export default HighlightedContent;