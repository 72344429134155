import React from 'react';
import Helmet from 'react-helmet';

const PageTitle = (props) => {

    let titleToDisplay = 'Adoption Home Study Services of Ohio';
    if (props.title) {
        titleToDisplay += ' - ' + props.title;
    }

    return (
        <Helmet title={ titleToDisplay } />
    );
}


export default PageTitle;