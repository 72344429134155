import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const NavigationLink = styled(NavLink)`
    font-family: ${props => props.theme.mainFont.fontFamily};
    letter-spacing: ${props => props.theme.mainFont.letterSpacing};
    font-weight: ${props => props.theme.mainFont.fontWeight}; 
    font-style: ${props => props.theme.mainFont.fontStyle};

    &:link {
        color: ${props => props.theme.colors.navSection.linkUnvisited};
    }

    &:visited {
        color: ${props => props.theme.colors.navSection.linkVisited};
    }

    &:hover {
        color: ${props => props.theme.colors.navSection.linkHover};
    }

    &:active {
        color: ${props => props.theme.colors.navSection.linkActive};
    } 

    display: inline-block;
    text-decoration: none;
`;

export default NavigationLink;