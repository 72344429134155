import React from "react";
import Page from "../organisms/page";
import Paragraph from "../atoms/paragraph";
import PageSection from "../organisms/pageSection";

const FeesPage = (props) => {
	return (
		<Page className={props.className} title="Fees">
			<PageSection title="Home Study Fee: $1,700 Domestic / $2,000 International">
				<Paragraph>
					All home studies are completed in compliance with Ohio state law and
					Ohio Department of Job and Family Services regulations. All home
					studies are completed by Ohio state-licensed social workers who are
					also Ohio state-certified adoption assessors. We never charge an
					application fee or charge for travel time!
				</Paragraph>
			</PageSection>

			<PageSection title="Home Study Update Fee: $800">
				<Paragraph>
					All Ohio adoption home studies are required to be updated every two
					years. Adoption Home Study Services of Ohio will notify you every two
					years prior to the expiration of your home study and offer to update
					your home study.
				</Paragraph>
			</PageSection>

			<PageSection title="Home Study Sharing Fee:  No Charge">
				<Paragraph>
					After completion of your home study, Adoption Home Study Services of
					Ohio, will send copies of your home study and supporting documents, at
					your request, to any child&#8209;placing agency, court, or attorney
					that you request, at no cost to you.
				</Paragraph>
			</PageSection>

			<PageSection title="Post-Placement Fee: $300">
				<Paragraph>
					After your child is placed, a social worker will need to visit you in
					your home and write a report that is sent to the court to report your
					child's progress. Each visit requires a report and has a $300 fee.
				</Paragraph>
			</PageSection>

			<PageSection title="Court Appearance Fee: $300">
				<Paragraph>
					A few Ohio probate courts require the attendance of the adoption
					assessor at the finalization hearing. Attendance at any court hearing
					is considered a post-placement visit and billed at the same fee amount
					as a post-placement visit.
				</Paragraph>
			</PageSection>

			<PageSection title="ICPC Document Preparation Fee: $350">
				<Paragraph>
					If you pursue a domestic adoption and your child is born outside the
					state of Ohio, your adoption paperwork needs to be prepared and
					processed through the US Interstate System (ICPC). Adoption Home Study
					Services of Ohio has significant experience working with ICPC and will
					prepare your documents in a manner that will make the process as easy
					and fast as possible. This fee is due at the time that you are matched
					with an out-of-state birth-mother. A few international adoptions also
					are processed through ICPC and will require this service.
				</Paragraph>
			</PageSection>

			<PageSection title="File Transfer Fee: $600">
				<Paragraph>
					If you request to transfer your entire home study file to another
					agency and end your relationship with us, or if you request to
					transfer an entire home study file to Adoption Home Study Services and
					end your relationship with the other agency, then a $500 transfer fee
					is charged. For any questions regarding the transfer process or
					transfer fees, please contact Brenda at homestudyohio@hotmail.com or
					on her cell at 330-206-2218.
				</Paragraph>
			</PageSection>

			<PageSection title="Large Family Assessment and Form Completion Fee: $400">
				<Paragraph>
					If the child(ren) to be placed will result in five or more children
					residing in your home, then Ohio rules require that a Large Family
					Assessment and a Large Family Assessment Form be completed.
				</Paragraph>
			</PageSection>

			<PageSection title="Completion of the 1699 Pre-Finalization Form: $350">
				<Paragraph>
					Many Ohio probate courts require the completion of the Ohio 1699
					Pre-Finalization form prior the finalization of an adoption.
				</Paragraph>
			</PageSection>

			<PageSection title="Addendum Fee: $150">
				<Paragraph>
					An addendum to your home study is required if a significant change
					occurs in your home after the original home study has been completed.
					Significant changes include changes such as a job change, a move to a
					new home, an addition of a family member, etc.
				</Paragraph>
			</PageSection>

			<PageSection title="Additional Costs">
				<Paragraph>
					During the home study process you will have a few additional costs
					such as the cost of completing fingerprint checks, cost of a well
					water inspection, medical examinations, and postage costs.
				</Paragraph>
			</PageSection>

			<PageSection title="Refund Policy">
				<Paragraph>
					Please see the Service Agreement for Refund Policy.
				</Paragraph>
			</PageSection>

			<PageSection title="Important Note">
				<Paragraph>
					Adoption Home Study Services of Ohio does not charge a separate
					application fee. There also is no charge for mileage. We will expedite
					your home study at no extra charge. We are a non-profit agency and
					work to keep our fees as low as possible. Our fees are among the
					lowest in the state of Ohio.
				</Paragraph>
			</PageSection>
		</Page>
	);
};

export default FeesPage;
