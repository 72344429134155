import React from 'react';
import styled from 'styled-components';
import Hyperlink from '../atoms/hyperlink';
import ExpandableIndicator from '../molecules/expandableIndicator';

const NavLinkList = (props) => {

    // This may change as we continue working on the styling, but for now we want these parent links to
    // always show the same color, regardless of their pseudoclasses. We accomplish this by overriding
    // the colors on the Hyperlink component by passing in props. If these props are missing, the
    // Hyperlink component will use the default link colors from the theme.

    return (
        <ContainerDiv {...props}> 
            <FlexWrapperForParentLinkAndExpandableIndicator>
                <ParentHyperlink 
                    className={props.className} 

                    unvisitedColor={props => props.theme.colors.navSection.parentLink}
                    visitedColor={props => props.theme.colors.navSection.parentLink}
                    hoverColor={props => props.theme.colors.navSection.parentLink}
                    activeColor={props => props.theme.colors.navSection.parentLink}
                    
                    title={props.title} 
                    href="#"
                    onClick={props.parentLinkClicked}> {props.title} 
                </ParentHyperlink>
                <ExpandableIndicator expanded={props.expanded} hidden={props.hideExpandableIcon} />
            </FlexWrapperForParentLinkAndExpandableIndicator>

            <StyledList {...props}>
                {props.children.map(
                    (child, index) => 
                        <li key={index}>{child}</li>)}
            </StyledList>        
        </ContainerDiv>        
    );    
}

const ContainerDiv = styled.div`
    display: block;
    background-color: ${props => props.theme.colors.main};
    padding: 18px;
    border-top: 2px solid ${props => props.theme.colors.accentPrimaryLight};
    border-bottom: 1px solid ${props => props.theme.colors.accentPrimary};
    margin: 4px 0;
    min-width: 12rem;
    height: ${props => props.expanded ? '6rem' : 'auto'};
`

const FlexWrapperForParentLinkAndExpandableIndicator = styled.div`
    display: flex;
    flex-direction: horizontal;
    justify-content: flex-start;
`

const StyledList = styled.ul`
    list-style-type: none;
    display: ${props => props.expanded ? 'block' : 'none'};
    text-align: left;
    margin-top: 6px;
`

const ParentHyperlink = styled(Hyperlink)`
    font-family: ${props => props.theme.navHeaderFont.fontFamily};
    letter-spacing: ${props => props.theme.navHeaderFont.letterSpacing};
    font-weight: ${props => props.theme.navHeaderFont.fontWeight}; 
    font-size: ${props => props.theme.navHeaderFont.fontSize};
    font-style: ${props => props.theme.navHeaderFont.fontStyle};

    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
`

export default NavLinkList;