import React from 'react';
import styled from 'styled-components';
import PageTitle from '../molecules/pageTitle';
import PageTitleDisplay from '../molecules/pageTitleDisplay';


const StyledSection = styled.section`
    padding: 0px 18px;    
    display: flex;
    flex-direction: column;
`;

const Page = (props) => {
    return (        
        <StyledSection className={props.className}>
            <PageTitle title={props.title}/>
            <PageTitleDisplay title={props.title} />
                   
            {props.children}

        </StyledSection>
    );
}

export default Page;