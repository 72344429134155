import React from 'react';
import styled from 'styled-components';
import logoHighRes from '../../images/ahs-logo-high.jpg';
import logoLowRes from '../../images/ahs-logo-low.jpg';
import sizeRange from '../../layout/breakpoints';

const SiteLogo = (props) => {
    return (
        <SytledImage 
            className={props.className} 
            src={logoLowRes} 
            srcSet={`${logoLowRes} 1x, ${logoHighRes} 2x`}
            alt="Logo is a crayon drawing of a house"/>
    );
}

// The max-width and max-height here are based off of the actual pixel size of the non-retina image.
// If we end up using a different sized image, these values should be changed to match.

const SytledImage = styled.img`
    height: 100%;
    max-width: 200px;
    max-height: 200px;
    vertical-align: middle;

    @media ${sizeRange.phoneOnly} {
        width: 3.2rem;
        height: 3.2rem;
    }

    @media ${sizeRange.phoneOrLarger} {
        width: 3.2rem;
        height: 3.2rem;
    }

    @media ${sizeRange.tabletOrLarger} {
        width: 7.5rem;
        height: 7.5rem;
    }
`;

export default SiteLogo;


