import React from 'react';
import styled from 'styled-components';


//Add styling here as needed. We'll wrap all of the <p>'s in this app with this component.
const StyledP = styled.p`
    font-family: ${props => props.theme.mainFont.fontFamily};
    letter-spacing: ${props => props.theme.mainFont.letterSpacing};
    font-weight: ${props => props.theme.mainFont.fontWeight}; 
    font-style: ${props => props.theme.mainFont.fontStyle}; 
    color: ${props => props.theme.colors.mainDark};
`;


const Paragraph = (props) => {
    return (
        <StyledP className={props.className}>{props.children}</StyledP>
    );
}

export default Paragraph;

