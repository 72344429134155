import React from 'react';
import styled from 'styled-components';
import NoBreakSpan from '../atoms/noBreakSpan';


const StyledDiv = styled.div`
    font-family: ${props => props.theme.lightFont.fontFamily};
    letter-spacing: ${props => props.theme.lightFont.letterSpacing};
    font-weight: ${props => props.theme.lightFont.fontWeight}; 
    font-style: ${props => props.theme.lightFont.fontStyle}; 
    margin: auto;
`;

const CopyrightNotice = (props) => {
    
    return (
        <StyledDiv className={props.className}>
            <NoBreakSpan>&copy; { new Date().getFullYear()}&nbsp;Adoption Home Study Services of Ohio, Inc.&nbsp;</NoBreakSpan>
            <NoBreakSpan>All rights reserved.</NoBreakSpan>
        </StyledDiv>
    );
}

export default CopyrightNotice;