import React from 'react'
import styled from 'styled-components'

const ExpandableIndicator = (props) => {
    if (props.expanded) {
        return <ArrowDown {...props}/>
    } else {
        return <ArrowRight {...props}/>
    }
}

// We're using the border sizes to draw a triangle, either pointing to the right if not
// expanded or down if expanded. https://css-tricks.com/the-shapes-of-css/
const ArrowRight = styled.div`
    display: ${props => props.hidden ? "none" : "block"};
    margin: auto 0 auto 1rem;
    width: 0;
    height: 0;
    border-top: .35rem solid transparent;
    border-left: .7rem solid ${props => props.theme.colors.mainLightest};
    border-bottom: .35rem solid transparent;
`

const ArrowDown = styled.div`
    display: ${props => props.hidden ? "none" : "block"};
    margin: auto 0 auto 1rem;
    width: 0;
    height: 0;
    border-top: .7rem solid ${props => props.theme.colors.mainLightest};
    border-left: .35rem solid transparent;
    border-right: .35rem solid transparent;
`

export default ExpandableIndicator;