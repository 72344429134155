import React from 'react';
import styled from 'styled-components';
import Header1 from '../atoms/header1';
import { NavLink } from 'react-router-dom';
import NoBreakSpan from '../atoms/noBreakSpan';
import SiteLogo from '../molecules/siteLogo';
import sizeRange from '../../layout/breakpoints';
import HamburgerMenu from './hamburgerMenu';

const HeaderSection = (props) => {
    return (
        <HeaderFlexContainer className={props.className}>                       
            <SiteTitle>
                <NoBreakSpan>Adoption Home Study&nbsp;</NoBreakSpan>
                <NoBreakSpan>Services of Ohio</NoBreakSpan>
            </SiteTitle>  
            <HamburgerWrapper>
                <HamburgerMenu {...props}/>
            </HamburgerWrapper>                    
            <LogoWrapper>  
                <NavLink to="/" title="Home" onClick={props.onSiteLogoClicked}>
                    <SiteLogo {...props}/> 
                </NavLink>  
            </LogoWrapper>
                                                     
        </HeaderFlexContainer>
    );
}

const HeaderFlexContainer = styled.header`
    background-color: ${props => props.theme.colors.main};
    border-width: 4px 0;
    border-style: solid;
    border-color: ${props => props.theme.colors.accentPrimaryLight};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    width: 100%;
`;

const SiteTitle = styled(Header1)`   
    color: ${props => props.theme.colors.mainLightest};
    order: 1;
    padding: 0px;
    justify-self: center;
    align-self: center;
    text-align: center;
    justify-self: center;
    flex-grow: 1;    
`;


// We're using order to pull the logo to the left visually, but for screen readers it's better for
// the site title to come first in the markup.

const HamburgerWrapper = styled.div`
    align-self: center;
    justify-self: center;
    height: auto;
    padding: 0px;
    margin: 16px 10px;

    @media ${sizeRange.tabletOrLarger} {
        margin: 16px 10px 16px 28px;
    }
`;

const LogoWrapper = styled.div`
    align-self: center;
    justify-self: center;
    height: auto;
    padding: 0px;
    margin: 16px 0px;

    @media ${sizeRange.tabletOrLarger} {
        margin: 16px 10px;
    }
`;

export default HeaderSection;