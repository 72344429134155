import React from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import HomePage from '../pages/homePage'
import UniqueAgencyPage from '../pages/uniqueAgencyPage'
import AgencyProfilePage from '../pages/agencyProfilePage';
import TestimonialsPage from '../pages/testimonialsPage';
import ServicesPage from '../pages/servicesPage';
import FeesPage from '../pages/feesPage';
import StartHomeStudyPage from '../pages/startHomeStudyPage';
import FormsPage from '../pages/formsPage';
import NotFoundPage from '../pages/notFoundPage';

const StyledMain = styled.main`
    background-color: ${props => props.theme.colors.mainLighter};
    border-width: 4px 0;
    border-style: solid;
    border-color: ${props => props.theme.colors.mainDark};
`

const MainContent = (props) => {
    return (
        <StyledMain className={props.className}>
            <Switch>
                <Route exact path='/' component={ HomePage } />
                <Route exact path='/unique' component={ UniqueAgencyPage } />
                <Route exact path='/profile' component={ AgencyProfilePage } />
                <Route exact path='/testimonials' component={ TestimonialsPage } />
                <Route exact path='/services' component={ ServicesPage } />
                <Route exact path='/fees' component={ FeesPage } />
                <Route exact path='/startHomeStudy' component={ StartHomeStudyPage } />
                <Route exact path='/forms' component={ FormsPage } />
                <Route component={NotFoundPage} />
            </Switch>
        </StyledMain>
    );
}

export default MainContent;
